@import url('Globals.css');

.App {
  height: 100%;
  width: 100%;
}







