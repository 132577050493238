body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.player {
  position: absolute;
  top: 0;
  left: 0;
}

.playButton{
  display: block;
  cursor: pointer;
width: 60px;
  height: 60px;
 background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 320.001 320.001" style="fill:%23373749;fill-opacity:1"><path d="M 301.90001,146.35055 47.261704,3.1165188 C 42.328102,0.34730202 36.312216,0.41096849 31.442326,3.2447678 26.54042,6.1094384 23.548462,11.32954 23.548462,16.995214 V 303.46328 c 0,5.66568 2.991958,10.88578 7.893864,13.75045 2.482626,1.43236 5.251934,2.16443 8.021059,2.16443 2.673763,0 5.379222,-0.66841 7.798319,-2.0371 L 301.90001,174.10703 c 4.99727,-2.83284 8.11647,-8.11661 8.11647,-13.87778 0,-5.76122 -3.1192,-11.04494 -8.11647,-13.87778 z" /></svg>');
  background-repeat: no-repeat;
}


.playButton:hover{
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 320.001 320.001" style="fill:%23e8b14a;fill-opacity:1"><path d="M 301.90001,146.35055 47.261704,3.1165188 C 42.328102,0.34730202 36.312216,0.41096849 31.442326,3.2447678 26.54042,6.1094384 23.548462,11.32954 23.548462,16.995214 V 303.46328 c 0,5.66568 2.991958,10.88578 7.893864,13.75045 2.482626,1.43236 5.251934,2.16443 8.021059,2.16443 2.673763,0 5.379222,-0.66841 7.798319,-2.0371 L 301.90001,174.10703 c 4.99727,-2.83284 8.11647,-8.11661 8.11647,-13.87778 0,-5.76122 -3.1192,-11.04494 -8.11647,-13.87778 z" /></svg>');
}

.btn{
  border: none;
  padding: 8px 15px;
  margin: 10px;
  text-decoration: none;
  background-color:#e8b14a;
  color: #373749;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.btn:hover{
  background-color:#F2BE5D;
}
