@import url('Globals.css');

.imgQuestion {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 1.2em;
}

.tintRed {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #ff225b;
  opacity: 0.5;
  position: absolute;
  z-index: 1;
}


.tintGreen {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #84e52a;
  opacity: 0.3;
  position: absolute;
  z-index: 1;
}



.continue {
  color: var(--white);

   text-align: center;
   opacity: 1.0;
   font-size: 200px;
   margin-Top: 200px;
   z-index: 10;
}



.question {
  padding: 10px 0px;
  margin: 0px;
  color: var(--white);
  text-align: center;

  width: 100%;
  height: 5%;

  position: absolute;
  top: 2%;
  z-index: 1;

  background-color: var(--yellow);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonAnswer {
    border: none;
    padding: 16px 20px 16px 20px;
    margin: 0;
    text-decoration: none;
    background-color: var(--yellow);
    color: var(--grey);
    font-size: 21px;
    text-align: center;
    border-radius: 6px;
    cursor: pointer;

    position: absolute;
    top: 50px;
    left: 100px;
    z-index: 1;
  }

.buttonAnswer:hover {
    background-color: var(--yellowLight);
  }





.pauseMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 1;
}

.pauseMenuFlex {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  margin-left: 30px;
}

.buttonMenuClickable {
  border: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.buttonMenu {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: var(--lightGrey);
}

.buttonMenu:hover {
  color: var(--white)
}

.buttonPause {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: var(--lightGrey)
}

.buttonPause:hover {
  color: var(--white)
}

.pauseButtonParentDiv {
  display: flex;
  flex-direction: row;
  margin: 0px 10px;
}

.help {
  position: absolute;
  bottom: 50px;
  left: 130px;
  z-index: 1;
  border-radius: 3px;

  background-color: var(--white);
}

.helpText {
  padding: 20px;
  font-size: 16px;
}

.quit {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;

  position: absolute;
  width: 400px;
  bottom: 50px;
  left: 170px;
  z-index: 1;
  border-radius: 3px;

  background-color: var(--white);
}

.quitText {
  text-align: center;
  padding: 20px 20px 0px 20px;
  font-size: 16px;
}

.quitText2 {
  text-align: center;
  padding: 0px 0px 20px 0px;
  font-size: 16px;
}

.buttonQuitConfrim {
  margin: 10px auto;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  background-color: var(--yellow);
}

.buttonQuitConfrim:hover {
  background-color: var(--yellowLight);
}

.confrimQuit {
  font-size: 16px;
  color: var(--white);
}


.timer {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1;

    width: 50px;
    height: 50px;
}

.timerRed {
  width: 50px;
  height: 50px;

  color: red;
}

.timerWhite {
  width: 50px;
  height: 50px;

  color: white;
}

.points {
  position: absolute;
  bottom: 30px;
  right: 60px;
  z-index: 1;
  font-size: 18px;
  color: white;

  width: 50px;
  height: 50px;
}

.index {
  position: absolute;
  bottom: 70px;
  right: 60px;
  z-index: 1;
  font-size: 22px;
  color: white;

  width: 50px;
  height: 50px;
}

.box {
  margin-top: 50px;
  width: 100px;
  height: 100px;
  background: #ff1c68;
  transform-origin: 50% 50%;
}
