@import url('Globals.css');

.mainAreaLogin {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;  
    margin-top: 10%;
}

.formLogin {
    display: flex;    
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;  
    height: auto;
    
    margin: 0;
    margin-top: 2%;
    margin-bottom: 2%;
}

.inputLogin {
    border: 0;
    margin: 10px;
    padding: 10px;
    font-size: 20px;

    background-color: var(--lightGrey);
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}