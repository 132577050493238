@import url('Globals.css');

.header {  
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: center;
  margin-top: 15%;
}

.titleLogo {
  max-width:600px;
  max-height:600px;
  height: auto;  
  width: auto;
}

.mainArea {
  display: flex; 
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;  
  margin-top: 22%;
}

.spustit {
  border: none;
  padding: 8px 14px 8px 14px;
  margin: 0;
  text-decoration: none;
  background-color: var(--yellow);
  color: var(--grey);
  font-size: 48px;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
}

.form {
  margin-top: 60%;
}

.box {
  margin-top: 50px;
  width: 100px;
  height: 100px;
  background: #ff1c68;
  transform-origin: 50% 50%;
}