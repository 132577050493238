:root { 
    --fontTitle: 'Helvetica';
    --fontNormal: 'Quicksand';
    --fontUserText: 'Open Sans';
    
    --white: #ffffff;
    --yellow: #EBB04B;
    --yellowLight: #f9c66d;
    --grey: #353743;
    --lightGrey: #e0e0e0;
    --traivaGreen: #c8e300;
    --traivaOrange: #e8b14a;
    --traivaBlue: #373749;
    --traivaPurple: #84486d;
    --traivaGrey: #dedee1;
}

.body {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin: 0;
}

.parentContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    width: 100%;
    height: 100%;
    opacity: 1;   
    margin: 0; 

    background-color: var(--white);      
}

.full-screenable-node {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  
    width: 100%;
    height: 100%;
    opacity: 1;   
    margin: 0; 

    background-color: var(--white);
}

.buttonGeneric {
    border: none;
    padding: 8px 14px 8px 14px;
    margin: 0;
    text-decoration: none;
    background-color: var(--yellow);
    color: var(--grey);    
    text-align: center;
    border-radius: 3px;
    cursor: pointer;
}
